/**
 * This file contains API function for login modules.
 */
import {http} from 'utils/http';

/**
 * Expected Output data
 */
export interface UserSuccess {
	username: string;
	email: string;
	id: number;
	first_name: string;
	last_name: string;
	credit: {
		quantity: number;
		free_quantity: number;
		feasability_quantity: number;
		id: number;
	};
	profile: {
		expert: boolean;
		company: string;
		address1: string;
		address2: string;
		postalCode: string;
		country: string;
		city: string;
		profile: string;
		rgpd: true;
		corporateFunction: string;
		indicator_tech_resources: number;
		indicator_tech_adequacy: number;
		indicator_tech_complexity: number;
		indicator_fin_investment: number;
		indicator_fin_competition: number;
		indicator_fin_roi: number;
		preferred_language: string;
	};
}

// ROUTE
export const apiUserRoute = () => '/users/me/';
// API FUNCTION
export const apiCurrentUser = () => {
	// console.log(apiUserRoute());
	return http.get<UserSuccess>(apiUserRoute());
};
